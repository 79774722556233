import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu", "toggler" ]
  static classes = [ "active" ]

  initialize() {
    this.isOpen = false
  }

  open() {
    if (!this.isOpen) {
      this.menuTargets.forEach((menuTarget) => {
        menuTarget.classList.add(this.activeClass)
      })

      this.togglerTarget.classList.add(this.activeClass)
      this.isOpen = true
    }
  }

  close() {
    if (this.isOpen) {
      this.menuTargets.forEach((menuTarget) => {
        menuTarget.classList.remove(this.activeClass)
      })

      this.togglerTarget.classList.remove(this.activeClass)
      this.isOpen = false
    }
  }

  toggle() {
    this.isOpen ? this.close() : this.open()
  }
}